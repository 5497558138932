import React, { useState, useEffect } from 'react';
import { JobApplication } from '../../models/JobApplication';
import { JobApplicationPotential, JobApplicationState, NoteType } from '../../models/Enums';

interface FieldMapperProps {
    csvData: any[]; // Raw CSV data
    onDataMapped: (mappedData: JobApplication[]) => void;
}

// Add this mapping at the top of your component or in a constants file
const friendlyFieldNames: { [key: string]: string } = {
    state: "State",
    company_name: "Company Name",
    company_linkedin_url: "Company LinkedIn URL",
    company_public_url: "Company Public URL",
    job_title: "Job Title",
    job_url: "Job URL",
    job_description: "Job Description",
    compensation_asked: "Compensation Asked",
    compensation_offered: "Compensation Offered",
    cover_letter: "Cover Letter",
    cv: "CV",
    origin: "Origin",
    potential: "Potential",
    created_at: "Created At",
    updated_at: "Updated At",
    applied_at: "Applied At",
    notes: "Notes",
};

const jobApplicationFields = [
    'company_name', 'company_linkedin_url', 'company_public_url', 'job_title', 'job_url',
    'job_description', 'compensation_offered', 'compensation_asked', 'potential', 'state',
    'cover_letter', 'cv', 'origin', 'created_at', 'updated_at', 'applied_at', 'notes'
];


const FieldMapper: React.FC<FieldMapperProps> = ({ csvData, onDataMapped }) => {
    const [fieldMappings, setFieldMappings] = useState<{ [key: string]: string }>({});
    const [areMappingsProcessed, setAreMappingsProcessed] = useState<boolean>(false);

    const handleFieldMappingChange = (jobField: string, csvField: string) => {
        setFieldMappings(prev => ({
            ...prev,
            [jobField]: csvField,
        }));
        setAreMappingsProcessed(false);
        onDataMapped([]);
    };

    const handleMapData = () => {
        setAreMappingsProcessed(true);
        const transformedData = csvData.map(row => {
            const jobApplication: Partial<JobApplication> = {
                notes: [], // Initialize notes as empty array
            };

            Object.keys(fieldMappings).forEach((jobField) => {
                const csvField = fieldMappings[jobField];
                let fieldData = row[csvField];
                if (!fieldData) {
                    fieldData = '';
                }
                if (jobField === 'notes') {
                    // Map to a single Note instance
                    jobApplication.notes = [
                        {
                            type: NoteType.Misc,
                            content: fieldData,
                            created_at: new Date(),
                            updated_at: new Date(),
                        },
                    ];
                } else if (jobField === 'state') {
                    if (Object.values(JobApplicationState).includes(fieldData as JobApplicationState)) {
                        // Validate and assign `state` field as JobApplicationState
                        jobApplication.state = fieldData as JobApplicationState;
                    } else {
                        jobApplication.state = JobApplicationState.Considering;
                    }
                } else if (jobField === 'potential') {
                    if (Object.values(JobApplicationPotential).includes(fieldData as JobApplicationPotential)) {
                        // Validate and assign `potential` field as JobApplicationPotential
                        jobApplication.potential = fieldData as JobApplicationPotential;
                    } else {
                        jobApplication.potential = JobApplicationPotential.Medium;
                    }
                } else {
                    jobApplication[jobField as keyof JobApplication] = fieldData;
                }
            });

            processDateFields(jobApplication);

            return jobApplication as JobApplication;
        });

        onDataMapped(transformedData);
    };

    const processDateFields = (jobApplication: Partial<JobApplication>) => {
        const now = new Date();
        const dateFields: (keyof JobApplication)[] = ['created_at', 'updated_at', 'applied_at'];

        dateFields.forEach((field) => {
            if (jobApplication[field]) {
                jobApplication[field] = new Date(jobApplication[field] as string) as any;
            } else {
                jobApplication[field] = now as any;
            }
        });
    }

    useEffect(() => {
        if (csvData.length > 0) {
            // Initialize empty field mappings
            const defaultMappings: { [key: string]: string } = {};
            jobApplicationFields.forEach(jobField => {
                defaultMappings[jobField] = ''; // Default to no mapping
            });
            setFieldMappings(defaultMappings);
        }
    }, [csvData]);

    return (
        <div>
            <h2 className='title is-4'>Map CSV Fields to Job Application Fields</h2>

            <div className="grid is-col-min-8">
                {jobApplicationFields.map((jobField, index) => (
                    <div className="cell">
                        <div key={index} className="field">
                            <label className="label" htmlFor={jobField}>{friendlyFieldNames[jobField]}</label>
                            <div className="control is-expanded">
                                <div className="select is-fullwidth">
                                    <select
                                        id={jobField}
                                        value={fieldMappings[jobField] || ''}
                                        onChange={(e) => handleFieldMappingChange(jobField, e.target.value)}
                                    >
                                        <option value="">Select a CSV Field</option>
                                        {Object.keys(csvData[0] || {}).map((csvField, idx) => (
                                            <option key={idx} value={csvField}>{csvField}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="control">
                <button className="button is-primary" disabled={areMappingsProcessed} onClick={handleMapData}>
                    Process mappings
                </button>
            </div>
        </div>
    );
};

export default FieldMapper;
