import { useState } from 'react';

function useLocalStorageSetting<T>(key: string, initialValue: T): [T, (value: T | ((prevValue: T) => T)) => void] {
  const [value, setValue] = useState<T>(() => {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue !== null ? JSON.parse(storedValue) : initialValue;
    } catch (error) {
      console.error('Error reading localStorage key:', key, error);
      return initialValue;
    }
  });

  const setValueAndLocalStorage = (newValue: T | ((prevValue: T) => T)) => {
    try {
      // If the newValue is a function, calculate the new value
      const valueToStore = newValue instanceof Function ? newValue(value) : newValue;

      setValue(valueToStore);
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error('Error setting localStorage key:', key, error);
    }
  };

  return [value, setValueAndLocalStorage];
}

export default useLocalStorageSetting;
