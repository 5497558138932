import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Privacy Policy - Jobsy</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="docs-container content">
            <h1 className="title is-1">Privacy Policy</h1>
            <h2 id="thegist" className="title is-2">
              The Gist
            </h2>
            <p>Your privacy is critically important to us. At Jobsy.Works, we have a few fundamental principles:</p>
            <ul>
              <li>
                We are thoughtful about the personal information we ask you to provide and the personal information that we
                collect about you through the operation of our services.
              </li>
              <li> We store personal information for only as long as we have a reason to keep it. </li>
              <li>
                We aim to make it as simple as possible for you to control what information on your website is shared
                publicly (or kept private), indexed by search engines, and permanently deleted.
              </li>
              <li> We help protect you from overreaching government demands for your personal information. </li>
              <li> We aim for full transparency on how we gather, use, and share your personal information. </li>
            </ul>
            <p>
              Below is our Privacy Policy, which incorporates and clarifies these principles. This Privacy Policy governs
              the use of any of our Services such as but not limited to: TimeTrackBill.com, Inequality, EpochTimestamp.com
              etc. From now on we may reffer to them by individual name of just as Services.
            </p>
            <h3 id="informationwecollect" className="title is-3">
              Information We Collect
            </h3>
            <p>
              We only collect information about you if we have a reason to do so–for example, to provide our Services, to
              communicate with you, or to make our Services better. We collect information in three ways: if and when you
              provide information to us, automatically through operating our Services, and from outside sources. Let’s go
              over the information that we collect.
            </p>
            <h4 id="_informationyouprovidetous_" className="title is-4">
              <em>Information You Provide to Us</em>
            </h4>
            <p>
              It’s probably no surprise that we collect information that you provide to us. The amount and type of
              information depends on the context and how we use the information. Here are some examples:
            </p>
            <ul>
              <li>
                <strong>Basic Account Information:</strong> We ask for basic information from you in order to set up your
                account. For example, we require individuals who sign up for a TimeTrackBill.com account to provide an email
                address along with a username or name. We may also require other information such as your company's address
                and company details, as well as information about your business partners or contractors/employees. Other
                services that we offer may not even need an account to be used, services such Inequality or our other games.
              </li>
              <li>
                <strong>Transaction and Billing Information:</strong> If you buy something from us – a subscription to a
                TimeTrackBill.com plan (for example via Recurring Payments or Simple Payments), you will provide additional
                personal and payment information that is required to process the transaction and your payment, such as your
                name, credit card information, and contact information.
              </li>
              <li>
                <strong>Communications with Us (Hi There!):</strong> You may also provide us information when you respond to
                surveys, communicate with our Engineers about a support question, post a question about any of our services
                in our public forums, or sign up for a newsletter. When you communicate with us via form, email, phone,
                TimeTrackBill.com comment, or otherwise, we store a copy of our communications (including any call
                recordings as permitted by applicable law).
              </li>
            </ul>
            <h4 id="_informationwecollectautomatically_" className="title is-4">
              <em>Information We Collect Automatically</em>
            </h4>
            <p>We also collect some information automatically:</p>
            <ul>
              <li>
                <strong>Log Information:</strong> Like most online service providers, we collect information that web
                browsers, mobile devices, and servers typically make available, such as the browser type, IP address, unique
                device identifiers, language preference, referring site, the date and time of access, operating system, and
                mobile network information. We collect log information when you use our Services.
              </li>
              <li>
                <strong>Usage Information:</strong> We collect information about your usage of our Services. For example, we
                collect information about the actions that administrators and users perform on TimeTrackBill.com – in other
                words, who did what, when and to what thing. We also collect information about what happens when you use our
                Services along with information about your device (e.g., screen size, name of cellular network, and mobile
                device manufacturer). We use this information to, for example, provide our Services to you, as well as get
                insights on how people use our Services, so we can make our Services better or debug issues that may end up
                annoying you.
              </li>
              <li>
                <strong>Location Information:</strong> We may determine the approximate location of your device from your IP
                address. We collect and use this information to, for example, calculate how many people visit our Services
                from certain geographic regions.
              </li>
              <li>
                <strong>Stored Information:</strong> We may access information stored on your mobile device via our mobile
                version of TimeTrackBill.com or any of our mobile apps and games. We access this stored information through
                your device operating system’s permissions. For example, if you give us permission to access the photographs
                on your mobile device’s camera roll, our Services may access the photos stored on your device when you
                upload an expense receipt to TimeTrackBill.com.
              </li>
              <li>
                <strong>Information from Cookies &amp; Other Technologies:</strong> A cookie is a string of information that
                a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time
                the visitor returns. Pixel tags (also called web beacons) are small blocks of code placed on websites and
                emails. We use cookies and other technologies like pixel tags to help us identify and track visitors, usage,
                and access preferences for our Services, as well as track and understand email campaign effectiveness. For
                more information about our use of cookies and other technologies for tracking, including how you can control
                the use of cookies, please see our <Link to="/cookies">Cookie Policy</Link>.
              </li>
            </ul>
            <h3 id="howandwhyweuseinformation" className="title is-3">
              How and Why We Use Information
            </h3>
            <h4 id="_purposesforusinginformation_" className="title is-4">
              <em>Purposes for Using Information</em>
            </h4>
            <p> We use information about you as mentioned above and for the purposes listed below: </p>
            <ul>
              <li>
                To provide our Services–for example, to set up and maintain your account and charge you for any of our paid
                Services;
              </li>
              <li>
                To further develop and improve our Services–for example by adding new features that we think our users will
                enjoy or will help them to create and manage their business more efficiently;
              </li>
              <li>
                To monitor and analyze trends and better understand how users interact with our Services, which helps us
                improve our Services and make them easier to use;
              </li>
              <li>
                To measure, gauge, and improve the effectiveness of our advertising, and better understand user retention
                and attrition--for example, we may analyze how many individuals purchased a paid plan after receiving a
                marketing message or the features used by those who continue to use our Services after a certain length of
                time;
              </li>
              <li>
                To monitor and prevent any problems with our Services, protect the security of our Services, detect and
                prevent fraudulent transactions and other illegal activities, fight spam, and protect the rights and
                property of Jobsy.Works and others, which may result in us declining a transaction or the use of our
                Services;
              </li>
              <li>
                To communicate with you, for example through an email, about offers and promotions offered by Jobsy.Works
                and others we think will be of interest to you, solicit your feedback, or keep you up to date on Jobsy.Works
                and our products (which you can unsubscribe from at any time);
              </li>
              <li>
                To personalize your experience using our Services, provide content recommendations, target our marketing
                messages to groups of our users (for example, those who have a particular plan with us or have been our user
                for a certain length of time), and serve relevant advertisements.
              </li>
            </ul>
            <h4 id="_legalbasesforcollectingandusinginformation_" className="title is-4">
              <em>Legal Bases for Collecting and Using Information</em>
            </h4>
            <p>
              A note here for those in the European Union about our legal grounds for processing information about you under
              EU data protection laws, which is that our use of your information is based on the grounds that: (1) The use
              is necessary in order to fulfill our commitments to you under the applicable terms of service or other
              agreements with you or is necessary to administer your account--for example, in order to enable access to our
              website on your device or charge you for a paid plan; or (2) The use is necessary for compliance with a legal
              obligation; or (3) The use is necessary in order to protect your vital interests or those of another person;
              or (4) We have a legitimate interest in using your information -- for example, to provide and update our
              Services; to improve our Services so that we can offer you an even better user experience; to safeguard our
              Services; to communicate with you; to measure, gauge, and improve the effectiveness of our advertising; to
              understand our user retention and attrition; to monitor and prevent any problems with our Services; and to
              personalize your experience; or (5) You have given us your consent--for example before we place certain
              cookies on your device and access and analyze them later on, as described in our <Link to="/cookies">Cookie Policy</Link>.
            </p>
            <h3 id="sharinginformation" className="title is-3">
              Sharing Information
            </h3>
            <h4 id="_howweshareinformation_" className="title is-4">
              <em>How We Share Information</em>
            </h4>
            <p>
              We do not sell our users’ private personal information. We share information about you in the limited
              circumstances spelled out below and with appropriate safeguards on your privacy:
            </p>
            <ul>
              <li>
                <strong>Subsidiaries, Employees, and Independent Contractors:</strong> We may disclose information about you
                to our subsidiaries, our employees, and individuals who are our independent contractors that need to know
                the information in order to help us provide our Services or to process the information on our behalf. We
                require our subsidiaries, employees, and independent contractors to follow this Privacy Policy for personal
                information that we share with them.
              </li>
              <li>
                <strong>Third Party Vendors:</strong> We may share information about you with third party vendors who need
                to know information about you in order to provide their services to us, or to provide their services to you.
                This group includes vendors that help us provide our Services to you (like payment providers that process
                your credit and debit card information, fraud prevention services that allow us to analyze fraudulent
                payment transactions, postal and email delivery services that help us stay in touch with you, customer chat
                and email support services that help us communicate with you, those that assist us with our marketing
                efforts (e.g. by providing tools for identifying a specific marketing target group or improving our
                marketing campaigns), those that help us understand and enhance our Services (like analytics providers), who
                may need information about you in order to, for example, provide technical or other support services to you.
                We require vendors to agree to privacy commitments in order to share information with them.
              </li>
              <li>
                <strong>Legal and Regulatory Requirements:</strong> We may disclose information about you in response to a
                subpoena, court order, or other governmental request.
              </li>
              <li>
                <strong>To Protect Rights, Property, and Others:</strong> We may disclose information about you when we
                believe in good faith that disclosure is reasonably necessary to protect the property or rights of
                Jobsy.Works, third parties, or the public at large. For example, if we have a good faith belief that there
                is an imminent danger of death or serious physical injury.
              </li>
              <li>
                <strong>Business Transfers:</strong> In connection with any merger, sale of company assets, or acquisition
                of all or a portion of our business by another company, or in the unlikely event that Jobsy.Works goes out
                of business or enters bankruptcy, user information would likely be one of the assets that is transferred or
                acquired by a third party. If any of these events were to happen, this Privacy Policy would continue to
                apply to your information and the party receiving your information may continue to use your information, but
                only consistent with this Privacy Policy.
              </li>
              <li>
                <strong>With Your Consent:</strong> We may share and disclose information with your consent or at your
                direction. For example, we may share your information with third parties with which you authorize us to do
                so.
              </li>
              <li>
                <strong>Aggregated or De-Identified Information:</strong> We may share information that has been aggregated
                or reasonably de-identified, so that the information could not reasonably be used to identify you. For
                instance, we may publish aggregate statistics about the use of our Services to facilitate customized ad
                campaigns on other platforms.
              </li>
              <li>
                <strong>Published Support Requests:</strong> And if you send us a request (for example, via a support email
                or one of our feedback mechanisms), we reserve the right to publish that request in order to help us clarify
                or respond to your request or to help us support other users.
              </li>
            </ul>
            <h3 id="howlongwekeepinformation" className="title is-3">
              How Long We Keep Information
            </h3>
            <p>
              We generally discard information about you when we no longer need the information for the purposes for which
              we collect and use it--which are described in the section above on How and Why We Use Information--and we are
              not legally required to continue to keep it. For example, we keep the web server logs that record information
              about a visitor to one of our websites, such as the visitor’s IP address, browser type, and operating system,
              for approximately 30 days. We retain the logs for this period of time in order to, among other things, analyze
              traffic to our websites and investigate issues if something goes wrong on one of our websites. As another
              example, when you delete something from your TimeTrackBill.com account, it stays in an inactive state for
              thirty days just in case you change your mind and would like restore that content--because starting again from
              scratch is no fun, at all. After the thirty days are up, the deleted content may remain on our backups and
              caches until purged.
            </p>
            <h3 id="security" className="title is-3">
              Security
            </h3>
            <p>
              While no online service is 100% secure, we work very hard to protect information about you against
              unauthorized access, use, alteration, or destruction, and take reasonable measures to do so, such as
              monitoring our Services for potential vulnerabilities and attacks.
            </p>
            <h3 id="choices" className="title is-3">
              Choices
            </h3>
            <p> You have several choices available when it comes to information about you: </p>
            <ul>
              <li>
                <strong>Limit the Information that You Provide:</strong> If you have an account with us, you can choose not
                to provide the optional account information, profile information, and transaction and billing information.
                Please keep in mind that if you do not provide this information, certain features of our Services–for
                example, paid content not be accessible.
              </li>
              <li>
                <strong>Limit Access to Information on Your Mobile Device:</strong> Your mobile device operating system
                should provide you with the ability to discontinue our ability to collect stored information or location
                information via our mobile website. If you do so, you may not be able to use certain features (like adding a
                photograph, for example).
              </li>
              <li>
                <strong>Opt-Out of Marketing Communications:</strong> You may opt out of receiving promotional
                communications from us. Just follow the instructions in those communications or let us know. If you opt out
                of promotional communications, we may still send you other communications, like those about your account and
                legal notices.
              </li>
              <li>
                <strong>Set Your Browser to Reject Cookies:</strong> At this time, Jobsy.Works does not respond to “do not
                track” signals across all of our Services. However, you can usually choose to set your browser to remove or
                reject browser cookies before using our websites, with the drawback that certain features of our websites
                may not function properly without the aid of cookies.
              </li>
              <li>
                <strong>Close Your Account:</strong> While we’d be very sad to see you go, if you no longer want to use our
                Services :( :( :( :(, you can close your account. Please keep in mind that we may continue to retain your
                information after closing your account, as described in How Long We Keep Information above--for example,
                when that information is reasonably needed to comply with (or demonstrate our compliance with) legal
                obligations such as law enforcement requests, or reasonably needed for our legitimate business interests.
              </li>
            </ul>
            <h3 id="yourrights" className="title is-3">
              Your Rights
            </h3>
            <p>
              If you are located in certain countries, including those that fall under the scope of the European General
              Data Protection Regulation (AKA the “GDPR”), data protection laws give you rights with respect to your
              personal data, subject to any exemptions provided by the law, including the rights to:
            </p>
            <ul>
              <li>Request access to your personal data;</li>
              <li>Request correction or deletion of your personal data;</li>
              <li>Object to our use and processing of your personal data;</li>
              <li> Request that we limit our use and processing of your personal data; and </li>
              <li>Request portability of your personal data.</li>
            </ul>
            <p>
              You can usually access, correct, or delete your personal data using your account settings and tools that we
              offer, but if you aren’t able to do that, or you would like to contact us about one of the other rights,
              scroll down to How to Reach Us to, well, find out how to reach us. EU individuals also have the right to make
              a complaint to a government supervisory authority.
            </p>
            <h3 id="howtoreachus" className="title is-3">
              How to Reach Us
            </h3>
            <p>
              If you have a question about this Privacy Policy, or you would like to contact us about any of the rights
              mentioned in the Your Rights section above, please <a href="mailto:support@jobsy.works">contact us</a>.
            </p>
            <h3 id="otherthingsyoushouldknowkeepreading" className="title is-3">
              Other Things You Should Know (Keep Reading!)
            </h3>
            <h4 id="_transferringinformation_" className="title is-4">
              <em>Transferring Information</em>
            </h4>
            <p>
              Because our Services are offered worldwide, the information about you that we process when you use the
              Services in the EU may be used, stored, and/or accessed by individuals operating outside the European Economic
              Area (EEA) who work for us, other members of our group of companies, or third party data processors. This is
              required for the purposes listed in the How and Why We Use Information section above. When providing
              information about you to entities outside the EEA, we will take appropriate measures to ensure that the
              recipient protects your personal information adequately in accordance with this Privacy Policy as required by
              applicable law. These measures include:
            </p>
            <ul>
              <li>
                In the case of US based entities, entering into European Commission approved standard contractual
                arrangements with them, or ensuring they have signed up to the <a href="https://www.privacyshield.gov/welcome">EU-US Privacy Shield</a>; or
              </li>
              <li>
                In the case of entities based in other countries outside the EEA, entering into European Commission approved
                standard contractual arrangements with them.
              </li>
            </ul>
            <p>
              You can ask us for more information about the steps we take to protect your personal information when
              transferring it from the EU.
            </p>
            <h4 className="title is-4">
              <em>Ads and Analytics Services Provided by Others</em>
            </h4>
            <p>
              Ads appearing on any of our Services may be delivered by advertising networks. Other parties may also provide
              analytics services via our Services. These ad networks and analytics providers may set tracking technologies
              (like cookies) to collect information about your use of our Services and across other websites and online
              services. These technologies allow these third parties to recognize your device to compile information about
              you or others who use your device. This information allows us and other companies to, among other things,
              analyze and track usage, determine the popularity of certain content, and deliver advertisements that may be
              more targeted to your interests. Please note this Privacy Policy only covers the collection of information by
              Jobsy.Works and does not cover the collection of information by any third party advertisers or analytics
              providers.
            </p>
            <h3 className="title is-3">Privacy Policy Changes</h3>
            <p>
              Although most changes are likely to be minor, Jobsy.Works may change its Privacy Policy from time to time.
              Jobsy.Works encourages visitors to frequently check this page for any changes to its Privacy Policy. Your
              further use of the Services after a change to our Privacy Policy will be subject to the updated policy. That’s
              it! Thanks for reading.
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
