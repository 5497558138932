import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const Navbar: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const { user, logout } = useAuth();
  const navbarRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const toggleNavbar = () => {
    setIsActive(!isActive);
  };

  // Close the menu when the location changes
  useEffect(() => {
    setIsActive(false);
  }, [location]);

  const handleClickOutside = (event: MouseEvent) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
      setIsActive(false); // Close the menu if the click is outside the navbar
    }
  };

  useEffect(() => {
    if (isActive) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isActive]);

  return (
    <nav
      className={
        process.env.NODE_ENV === 'production' ? 'navbar is-fixed-top' : 'navbar is-fixed-top has-background-warning'
      }
      role="navigation"
      aria-label="main navigation"
      ref={navbarRef}
    >
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <img src="/android-chrome-512x512.png" alt="Jobsy Logo" />
          <strong>Jobsy</strong>
        </Link>

        <button
          className={`navbar-burger ${isActive ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarJobsy"
          onClick={toggleNavbar}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div id="navbarJobsy" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
        <div className="navbar-start">
          <div className="navbar-item">
            <Link to="/" className="button is-text">
              Home
            </Link>
            <Link to="/chrome-extension" className="button is-text">
              Chrome Extension
            </Link>
            <Link to="/data-import" className="button is-text">
              Data import (might explode)
            </Link>
            <Link to="/about" className="button is-text">
              About
            </Link>
          </div>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              {user ? (
                <>
                  <Link to="/create-job-application" className="button is-link">
                    New Job Application
                  </Link>
                  <button className="button" onClick={logout}>
                    Logout
                  </button>
                </>
              ) : (
                <>
                  <Link to="/signup" className="button is-primary">
                    Sign Up
                  </Link>
                  <Link to="/signin" className="button">
                    Sign In
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
