import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { JobApplicationPotential, JobApplicationState } from '../../models/Enums';
import { JobApplication } from '../../models/JobApplication';
import { addJobApplication, editJobApplication } from '../../services/DatabaseService';

interface JobApplicationFormProps {
  jobApplication?: JobApplication | null;
}

function JobApplicationForm({ jobApplication = null }: JobApplicationFormProps) {
  const availableStates = Object.values(JobApplicationState);
  const availablePotentials = Object.values(JobApplicationPotential);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [companyName, setCompanyName] = useState<string>(jobApplication?.company_name || '');
  const [companyLinkedInUrl, setCompanyLinkedInUrl] = useState<string>(jobApplication?.company_linkedin_url || '');
  const [companyPublicUrl, setCompanyPublicUrl] = useState<string>(jobApplication?.company_public_url || '');
  const [jobTitle, setJobTitle] = useState<string>(jobApplication?.job_title || '');
  const [jobUrl, setJobUrl] = useState<string>(jobApplication?.job_url || '');
  const [jobDescription, setJobDescription] = useState<string>(jobApplication?.job_description || '');
  const [state, setState] = useState<JobApplicationState>(jobApplication?.state || availableStates[0]);
  const [origin, setOrigin] = useState<string>(jobApplication?.origin || '');
  const [potential, setPotential] = useState<JobApplicationPotential>(
    jobApplication?.potential || availablePotentials[0]
  );
  const [compensationAsked, setCompensationAsked] = useState<string>(jobApplication?.compensation_asked || '');
  const [compensationOffered, setCompensationOffered] = useState<string>(jobApplication?.compensation_offered || '');
  const [cv, setCv] = useState<string>(jobApplication?.cv || '');
  const [coverLetter, setCoverLetter] = useState<string>(jobApplication?.cover_letter || '');
  const [appliedAt, setAppliedAt] = useState<Date | null>(jobApplication?.applied_at || null);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const newApplication: JobApplication = {
        company_name: companyName,
        company_linkedin_url: companyLinkedInUrl,
        company_public_url: companyPublicUrl,
        job_title: jobTitle,
        job_url: jobUrl,
        job_description: jobDescription,
        state: state,
        origin: origin,
        potential: potential,
        compensation_asked: compensationAsked,
        compensation_offered: compensationOffered,
        cover_letter: coverLetter,
        cv: cv,
        notes: [],
        applied_at: appliedAt,
        created_at: jobApplication?.created_at || new Date(),
        updated_at: new Date(),
      };
      let appId = jobApplication?.id;
      if (jobApplication) {
        // editing an existing application
        newApplication.id = appId;
        await editJobApplication(newApplication);
      } else {
        // adding a new application
        appId = (await addJobApplication(newApplication)).id!;
      }
      navigate(`/details/${appId}`);
    } catch (error) {
      console.error('Error adding job application to Firestore:', error);
    }
    setIsLoading(false);
  };

  const handleCancel = (): void => {
    if (jobApplication) {
      navigate(`/details/${jobApplication.id}`);
    } else {
      navigate('/');
    }
  }

  return (
    <React.Fragment>

      <form className="mgt-large" onSubmit={handleSubmit}>

        <div className='columns'>
          <div className='column'>
            <div className="field">
              <label className="label" htmlFor='companyName'>Company Name</label>
              <div className="control">
                <input
                  id='companyName'
                  className="input"
                  type="text"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className='column'>
            <div className="field">
              <label className="label" htmlFor='origin'>Origin</label>
              <div className="control">
                <input
                  id='origin'
                  className="input"
                  type="text"
                  placeholder="Origin of the application (referral, job board, linkedin, etc)"
                  value={origin}
                  onChange={(e) => setOrigin(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label" htmlFor='companyPublicUrl'>Company Public URL</label>
              <div className="control">
                <input
                  id='companyPublicUrl'
                  className="input"
                  type="text"
                  placeholder="Company Public URL"
                  value={companyPublicUrl}
                  onChange={(e) => setCompanyPublicUrl(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label" htmlFor='companyLinkedinUrl'>Company LinkedIn URL</label>
              <div className="control">
                <input
                  id='companyLinkedinUrl'
                  className="input"
                  type="text"
                  placeholder="URL to LinkedIn"
                  value={companyLinkedInUrl}
                  onChange={(e) => setCompanyLinkedInUrl(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='columns'>
          <div className='column'>
            <div className="field">
              <label className="label" htmlFor='jobTitle'>Job Title</label>
              <div className="control">
                <input
                  id='jobTitle'
                  className="input"
                  type="text"
                  placeholder="Job Title"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className='column'>
            <div className="field">
              <label className="label" htmlFor='jobUrl'>Job URL</label>
              <div className="control">
                <input
                  id='jobUrl'
                  className="input"
                  type="text"
                  placeholder="Job URL"
                  value={jobUrl}
                  onChange={(e) => setJobUrl(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor='jobDescription'>Job Description</label>
          <div className="control">
            <ReactQuill id='jobDescription' theme="snow" value={jobDescription} onChange={setJobDescription} />
          </div>
        </div>

        <div className='columns'>
          <div className='column'>
            <div className="field">
              <label className="label" htmlFor='compensationOffered'>Compensation Offered</label>
              <div className="control">
                <input
                  id='compensationOffered'
                  className="input"
                  type="text"
                  placeholder="How much you were offered"
                  value={compensationOffered}
                  onChange={(e) => setCompensationOffered(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className='column'>
            <div className="field">
              <label className="label" htmlFor='compensationAsked'>Compensation Asked</label>
              <div className="control">
                <input
                  id='compensationAsked'
                  className="input"
                  type="text"
                  placeholder="How much you asked for"
                  value={compensationAsked}
                  onChange={(e) => setCompensationAsked(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='columns'>
          <div className='column is-one-quarter'>
            <div className="field is-expanded">
              <label className="label" htmlFor='potential'>Potential</label>
              <div className="select is-fullwidth">
                <select id='potential' onChange={(e) => setPotential(e.target.value as JobApplicationPotential)}>
                  {availablePotentials.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='column is-one-quarter'>
            <div className="field is-expanded">
              <label className="label" htmlFor='state'>State</label>
              <div className="select is-fullwidth">
                <select id='state' onChange={(e) => setState(e.target.value as JobApplicationState)}>
                  {availableStates.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className='columns'>
          <div className='column'>
            <div className="field">
              <label className="label" htmlFor='appliedAt'>Applied At</label>
              <div className="control">
                <DatePicker
                  id='appliedAt'
                  selected={appliedAt}
                  onChange={(date) => setAppliedAt(date)}
                  className="input is-fullwidth" // Applies Bulma's input styling
                  popperPlacement="bottom-end"
                />
              </div>
            </div>
          </div>
          <div className='column'>
            <div className="field">
              <label className="label" htmlFor='cv'>CV</label>
              <div className="control">
                <input
                  id='cv'
                  className="input"
                  type="text"
                  placeholder="Name of or link to your CV"
                  value={cv}
                  onChange={(e) => setCv(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor='coverLetter'>Cover Letter</label>
          <div className="control">
            <ReactQuill id='coverLetter' theme="snow" value={coverLetter} onChange={setCoverLetter} />
          </div>
        </div>

        <div className='buttons'>
          <button type='submit' className={`button is-success is-flex-grow-1 ${isLoading ? 'is-loading' : ''}`} disabled={isLoading}>Save</button>
          <button type='button' onClick={handleCancel} className={`button is-light is-flex-grow-1 ${isLoading ? 'is-loading' : ''}`} disabled={isLoading}>Cancel</button>
        </div>
      </form>
    </React.Fragment>
  );
}

export default JobApplicationForm;
