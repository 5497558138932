import Papa from 'papaparse';
import React, { useState } from 'react';
import './CsvImporter.scss';

interface CSVImporterProps {
    onDataParsed: (data: any[] | null) => void;
}

const CsvImporter: React.FC<CSVImporterProps> = ({ onDataParsed }) => {
    const [fileName, setFileName] = useState<string>(''); // State to store file name

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            setFileName(file.name);
            parseCSV(file);
        }
    };

    const parseCSV = (file: File) => {
        Papa.parse(file, {
            complete: (result) => {
                console.log('Parsed Data:', result);
                onDataParsed(result.data);
            },
            header: true, // If the CSV file has a header row, set to true
            skipEmptyLines: true, // Skip any empty lines in the CSV
        });
    };

    const handleRemoveFile = () => {
        onDataParsed(null);
        setFileName(''); // Clear the file name
    };

    return (
        <div className="container">
            <h2 className="title is-4">Select CSV file</h2>

            <div className="upload-container">
                <div className="file is-primary mb-0">
                    <label className="file-label">
                        <input
                            className="file-input"
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                        <span className="file-cta">
                            <span className="file-icon">
                                <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label">{fileName ? fileName : 'Choose file...'}</span>
                        </span>
                    </label>
                </div>

                {fileName && (
                    <button className="button is-danger" onClick={handleRemoveFile}>
                        Remove File
                    </button>
                )}
            </div>
        </div>

    );
};

export default CsvImporter;