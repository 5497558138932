import React from "react";
import { JobApplication } from "../../models/JobApplication";

interface JobApplicationTableProps {
    data: JobApplication[];
}

// Define friendly names for the table headers
const friendlyFieldNames: { [key: string]: string } = {
    company_name: "Company Name",
    company_linkedin_url: "Company LinkedIn URL",
    company_public_url: "Company Public URL",
    state: "State",
    job_title: "Job Title",
    job_url: "Job URL",
    job_description: "Job Description",
    compensation_asked: "Compensation Asked",
    compensation_offered: "Compensation Offered",
    cover_letter: "Cover Letter",
    cv: "CV",
    origin: "Origin",
    potential: "Potential",
    created_at: "Created At",
    updated_at: "Updated At",
    applied_at: "Applied At",
    notes: "Notes",
};

const JobApplicationsDetailedList: React.FC<JobApplicationTableProps> = ({ data }) => {
    // Get all fields of JobApplication
    const fields = Object.keys(friendlyFieldNames);

    return (
        <div className="table-container">
            <table className="table is-striped is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        {fields.map((field) => (
                            <th key={field}>{friendlyFieldNames[field]}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((application, rowIndex) => (
                        <tr key={rowIndex}>
                            {fields.map((field) => (
                                <td key={field}>
                                    {field === "notes"
                                        ? application.notes.map((note, index) => <div key={index}>{note.content}</div>)
                                        : application[field as keyof JobApplication]?.toString() || ""}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default JobApplicationsDetailedList;
