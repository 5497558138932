import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const LandingPage: React.FC = () => {
    const { user } = useAuth();

    return (
        <React.Fragment>
            <Helmet>
                <title>Jobsy - Your Job Application Tracker</title>
            </Helmet>
            <section className="hero is-bold is-fullheight">
                <div className="hero-body">
                    <div className="columns is-vcentered">
                        {/* Left Column: Text and Action */}
                        <div className="column is-half-desktop">
                            <h1 className="title is-1">Apply smarter. Track better.</h1>
                            <h2 className="is-size-3">Jobsy is here to help you stay organized and land your dream
                                job.</h2>
                            <div className="buttons mt-4">
                                {user && <Link to="/" className="button is-primary is-large">
                                    Get Started
                                </Link>}
                                {!user && <Link to="/signup" className="button is-primary is-large">
                                    Get Started
                                </Link>}
                            </div>
                        </div>
                        {/* Right Column: Image */}
                        <div className="column is-half-desktop">
                            <figure className="image">
                                <img
                                    src="laptop-transparent.png"
                                    alt="A laptop with Jobsy openend on screen"
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-half">
                            <h2 className="title">Keep Track of Your Job Applications</h2>
                            <p className="content">
                                Applying for multiple jobs can be overwhelming. Jobsy makes it easy to track where you've applied,
                                follow up with your contacts, and manage deadlines—all in one place.
                            </p>
                        </div>
                        <div className="column is-half">
                            <figure className="image">
                                <img
                                    src="screenshot-list-small-window.png"
                                    alt="Job tracking visual"
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section has-background-light">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-half">
                            <figure className="image">
                                <img
                                    src="iphone-notes-transparent.png"
                                    alt="Note-taking feature visual"
                                />
                            </figure>
                        </div>
                        <div className="column is-half">
                            <h2 className="title">Never Miss a Detail</h2>
                            <p className="content">
                                Jobsy’s powerful note-taking feature lets you jot down important details from interviews, track
                                conversations, and store any information that matters.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container has-text-centered">
                    <h2 className="title">How Jobsy Can Help You</h2>
                    <div className="columns">
                        <div className="column">
                            <div className="box">
                                <h3 className="title is-5">Organize Applications</h3>
                                <p>Stay on top of your job search by keeping all your applications in one place.</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="box">
                                <h3 className="title is-5">Track Your Progress</h3>
                                <p>Know exactly where you stand with every application, from submission to offer.</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="box">
                                <h3 className="title is-5">Boost Productivity</h3>
                                <p>Spend less time organizing and more time preparing for interviews.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer is-light">
                <div className="content has-text-centered">
                    <p>
                        <strong>Jobsy</strong> &copy; {new Date().getFullYear()} |{' '}
                        <Link to="/">
                            jobsy.works
                        </Link>
                    </p>
                    <p>
                        <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/cookies">Cookie Policy</Link>
                    </p>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default LandingPage;
