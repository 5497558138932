import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import JobApplicationForm from '../../components/job-applications/JobApplicationForm';
import { JobApplication } from '../../models/JobApplication';
import { getJobApplication } from '../../services/DatabaseService';

const JobApplicationEdit: React.FC = () => {
  const { application_id } = useParams();
  const [jobApplication, setJobApplication] = useState<JobApplication | null>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [formTitle, setFormTitle] = useState<string>('Create job application');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (application_id) {
          const data = await getJobApplication(application_id);
          setJobApplication(data);
          setIsEdit(true);
          setFormTitle(`Edit ${data?.company_name}`);
        }
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [application_id]);

  if (isLoading) {
    return <Loading />;
  }

  if (isEdit && !jobApplication) {
    return <div className="notification is-danger">Job Application not found!</div>;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Job Application {isEdit ? 'Edit' : 'Create'} - Jobsy</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <h1 className="title is-3">{formTitle}</h1>
          <JobApplicationForm jobApplication={jobApplication} />
        </div>
      </section>
    </React.Fragment>
  );
};
export default JobApplicationEdit;
