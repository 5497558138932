import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { signInEmailAndPassword } from '../../services/AuthenticationService';

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await signInEmailAndPassword(email, password);
      setError('');
      navigate('/');
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Sign In - Jobsy</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="container is-fluid mt-6">
            <div className="columns is-centered">
              <div className="column is-half">
                <div className="box">
                  <h2 className="title is-4 has-text-centered">Sign In</h2>
                  {error && <p className="notification is-danger">{error}</p>}
                  <form onSubmit={handleSignIn}>
                    <div className="field">
                      <label className="label">Email</label>
                      <div className="control has-icons-left">
                        <input
                          className="input"
                          type="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Password</label>
                      <div className="control has-icons-left">
                        <input
                          className="input"
                          type="password"
                          placeholder="Enter your password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-lock"></i>
                        </span>
                      </div>
                    </div>

                    <div className="field">
                      <div className="control">
                        <button type="submit" className="button is-primary is-fullwidth">
                          Sign In
                        </button>
                      </div>
                    </div>
                    <p className="has-text-centered">
                      Don't have an account?{' '}
                      <Link to="/signup" className="has-text-primary">
                        Sign up here
                      </Link>
                      .
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SignIn;
