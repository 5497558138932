import React, { useState } from 'react';
import { JobApplicationState } from '../../models/Enums';
import { JobApplication } from '../../models/JobApplication';
import { addJobApplication } from '../../services/DatabaseService';

interface JobApplicationFormMiniProps {
  onAdd?: (newApplication: JobApplication) => void;
}

function JobApplicationFormMini({ onAdd }: JobApplicationFormMiniProps) {
  const [companyName, setCompanyName] = useState<string>('');
  const [origin, setOrigin] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const newApplication: JobApplication = {
        company_name: companyName,
        origin: origin,
        state: JobApplicationState.Considering,
        created_at: new Date(),
        updated_at: new Date(),
        notes: [],
      };
      const createdJobApp = await addJobApplication(newApplication);

      setCompanyName('');
      setOrigin('');

      if (onAdd) {
        onAdd(createdJobApp);
      }
    } catch (error) {
      console.error('Error adding job application to Firestore:', error);
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <form className="quick-add-container" onSubmit={handleSubmit}>
        <div className="columns is-vcentered is-multiline is-align-items-flex-end ">
          <div className="column">
            <h3 className='title is-5 mb-2'>Quick add</h3>
          </div>
          <div className="column is-one-third">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Origin of the application (referral, job board, linkedin, etc)"
                  value={origin}
                  onChange={(e) => setOrigin(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <div className="control">
                <button type="submit" disabled={!companyName.trim()} className={`button is-success is-fullwidth ${isLoading ? 'is-loading' : ''}`}>
                  Quick add
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}

export default JobApplicationFormMini;
