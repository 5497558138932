import React from 'react';
import { JobApplicationPotential } from '../../models/Enums';

interface PotentialTagProps {
    potential: JobApplicationPotential;
}

const PotentialTag: React.FC<PotentialTagProps> = ({ potential }) => {
    const getTagClass = (value: JobApplicationPotential) => {
        switch (value) {
            case JobApplicationPotential.Low:
                return 'tag is-danger'; // Red for Low
            case JobApplicationPotential.Medium:
                return 'tag is-warning'; // Yellow for Medium
            case JobApplicationPotential.High:
                return 'tag is-success'; // Green for High
            default:
                return 'tag';
        }
    };

    return <span className={getTagClass(potential)}>{potential}</span>;
};

export default PotentialTag;
