import React from 'react';
import { Helmet } from 'react-helmet';
import { useExtensionFirebaseTokenSend } from '../../hooks/useExtensionFirebaseTokenSend';
import { useExtensionPing } from '../../hooks/useExtensionPing';
import { Link } from 'react-router-dom';

const ChromeExtension: React.FC = () => {
    const isExtensionInstalled = useExtensionPing();
    const { status, error } = useExtensionFirebaseTokenSend();

    const chromeExtensionLink = process.env.REACT_APP_CHROME_EXTENSION_LINK;

    return (<React.Fragment>
        <Helmet>
            <title>Chrome Extension - Jobsy</title>
        </Helmet>

        <section className="hero">
            <div className="hero-body">
                {chromeExtensionLink && <div className="container has-text-centered">
                    <h1 className="title is-1 has-text-danger">Jobsy Chrome Extension</h1>
                    <div className="is-size-5">
                        {isExtensionInstalled === null && <p>Checking for extension...</p>}
                        {isExtensionInstalled === true && <p>Extension is installed and reachable!</p>}
                        {isExtensionInstalled === false &&
                            <React.Fragment>
                                <p className='mb-1'>Extension not detected.</p>
                                <p><a className='button is-link' href={chromeExtensionLink}>Get it here!</a></p>
                            </React.Fragment>
                        }
                    </div>
                    <div className="is-size-6 mt-2">
                        {status === "loading" && <p>Authenticating extension...</p>}
                        {status === "success" && <p>Extension authenticated successfully! You can close this page.</p>}
                        {isExtensionInstalled && status === "error" && <p className='has-text-danger'>Error authenticating the extension. Please contact support at support@jobsy.works Error: {error}</p>}
                        {status === null && <p>Awaiting authentication...</p>}
                    </div>
                    <div className="buttons is-centered mt-5">
                        <Link to="/" className="button is-info">
                            Back to Home
                        </Link>
                    </div>
                </div>
                }
            </div>
        </section>
    </React.Fragment>
    )
};

export default ChromeExtension;
