import React from 'react';
import { JobApplicationState } from '../../models/Enums';

interface StateTagProps {
  state: JobApplicationState;
}

const StateTag: React.FC<StateTagProps> = ({ state }) => {
  const getTagClass = (value: JobApplicationState) => {
    switch (value) {
      case JobApplicationState.Considering:
        return 'tag is-light';
      case JobApplicationState.Snoozed:
        return 'tag is-danger';
      case JobApplicationState.Dismissed:
        return 'tag is-danger';
      case JobApplicationState.Applied:
        return 'tag is-warning';
      case JobApplicationState.Interviewing:
        return 'tag is-warning';
      case JobApplicationState.Offered:
        return 'tag is-warning';
      case JobApplicationState.Accepted:
        return 'tag is-success';
      case JobApplicationState.Rejected:
        return 'tag is-danger'
      default:
        return 'tag';
    }
  };

  return <span className={getTagClass(state)}>{state}</span>;
};

export default StateTag;
