import React, { useEffect } from 'react';
import useBulmaBreakpoint from '../../hooks/useBulmaBreakpoint';
import useLocalStorageSetting from '../../hooks/useLocalStorageSetting';
import { JobApplicationState } from '../../models/Enums'; // Assuming JobApplicationState is an enum
import { JobApplication } from '../../models/JobApplication';
import './JobApplicationStats.scss';

interface JobApplicationStatsProps {
  jobApplications: JobApplication[];
  onSelectedStatesChange: (selectedStates: string[]) => void;
}

const JobApplicationStats: React.FC<JobApplicationStatsProps> = ({ jobApplications, onSelectedStatesChange }) => {
  const [selectedStates, setSelectedStates] = useLocalStorageSetting<string[]>('selectedStates', []);
  const breakpoint = useBulmaBreakpoint();

  // Calculate the count of applications for each state
  const applicationStats = Object.values(JobApplicationState).reduce((acc: Record<string, number>, state) => {
    acc[state] = jobApplications.filter((application) => application.state === state).length;
    return acc;
  }, {});

  // Split the states into two halves
  const states = Object.keys(applicationStats);

  const toggleState = (state: string) => {
    setSelectedStates(
      (prevSelected: string[]) =>
        prevSelected.includes(state)
          ? prevSelected.filter((s) => s !== state) // Deselect state
          : [...prevSelected, state] // Select state
    );
  };

  useEffect(() => {
    onSelectedStatesChange(selectedStates);
  }, [selectedStates, onSelectedStatesChange]);

  const isStateSelected = (state: string) => selectedStates.includes(state);

  return (
    <React.Fragment>
      {breakpoint === 'mobile' ? (
        <div className="columns mb-1 is-1 is-mobile is-multiline">
          {states.map((state) => (
            <div key={state} className='column'>
              <button
                key={state}
                className={`button is-fullwidth ${isStateSelected(state) ? 'is-success is-selected' : ''}`}
                onClick={() => toggleState(state)}
              >
                {state} ({applicationStats[state]})
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="columns is-1 is-mobile stats-container">
          {states.map((state) => (
            <div key={state} className="column has-text-centered">
              <div
                className={`box ${isStateSelected(state) ? 'has-background-success-light' : ''}`}
                onClick={() => toggleState(state)}
              >
                <p className="heading">{state}</p>
                <p className="title">{applicationStats[state]}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default JobApplicationStats;
