import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Loading from '../../components/common/Loading';
import JobApplicationFormMini from '../../components/job-applications/JobApplicationFormMini';
import JobApplicationsList from '../../components/job-applications/JobApplicationsList';
import { JobApplication } from '../../models/JobApplication';
import { getAllJobApplications } from '../../services/DatabaseService';


const HomePage: React.FC = () => {
  const [applications, setApplications] = useState<JobApplication[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getAllJobApplications();
        setApplications(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data from Firestore:', error);
      }
    };

    fetchData();
  }, []);

  const handleAddJobApplication = (newApplication: JobApplication) => {
    setApplications((prevApplications) => [newApplication, ...prevApplications]);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Home - Jobsy</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <JobApplicationFormMini onAdd={handleAddJobApplication} />
          <hr className='has-background-grey' />
          {loading && <Loading />}
          {!loading && <JobApplicationsList applications={applications} />}
        </div>
      </section>
    </React.Fragment>
  );
};
export default HomePage;
