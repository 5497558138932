import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CollapsibleSection from '../../components/common/CollapsibleSection';
import Loading from '../../components/common/Loading';
import PotentialTag from '../../components/job-applications/PotentialTag';
import StateTag from '../../components/job-applications/StateTag';
import NotesList from '../../components/notes/NotesList';
import { JobApplication } from '../../models/JobApplication';
import { deleteJobApplication, editJobApplication, getJobApplication } from '../../services/DatabaseService';
import JAAttribute from '../../components/job-applications/inline-edit/JAAttribute';
import { JobApplicationPotential, JobApplicationState } from '../../models/Enums';
import { set } from 'date-fns';
import DatePicker from 'react-datepicker';

const JobApplicationDetails: React.FC = () => {
  const { application_id } = useParams();
  const [jobApplication, setJobApplication] = useState<JobApplication | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [jobTitle, setJobTitle] = useState<string>('');
  const [jobUrl, setJobUrl] = useState<string>('');
  const [state, setState] = useState<JobApplicationState>(JobApplicationState.Considering);
  const [potential, setPotential] = useState<JobApplicationPotential>(JobApplicationPotential.Low);
  const [appliedAt, setAppliedAt] = useState<Date | null>(null);
  const [cv, setCv] = useState<string>('');
  const [origin, setOrigin] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [companyPublicUrl, setCompanyPublicUrl] = useState<string>('');
  const [companyLinkedInUrl, setCompanyLinkedInUrl] = useState<string>('');
  const [compensationAsked, setCompensationAsked] = useState<string>('');
  const [compensationOffered, setCompensationOffered] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (application_id) {
          setIsLoading(true);
          const data = await getJobApplication(application_id);
          setJobApplication(data);

          setJobTitle(data?.job_title ? data?.job_title : '');
          setJobUrl(data?.job_url ? data?.job_url : '');
          setOrigin(data?.origin ? data?.origin : '');
          setState(data?.state ? data?.state : JobApplicationState.Considering);
          setPotential(data?.potential ? data?.potential : JobApplicationPotential.Low);
          setAppliedAt(data?.applied_at ? data?.applied_at : null);
          setCv(data?.cv ? data?.cv : '');
          setCompanyName(data?.company_name ? data?.company_name : '');
          setCompanyPublicUrl(data?.company_public_url ? data?.company_public_url : '');
          setCompanyLinkedInUrl(data?.company_linkedin_url ? data?.company_linkedin_url : '');
          setCompensationAsked(data?.compensation_asked ? data?.compensation_asked : '');
          setCompensationOffered(data?.compensation_offered ? data?.compensation_offered : '');
        }
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [application_id]);

  if (isLoading) {
    return <Loading />;
  }

  if (!jobApplication) {
    return <div className="notification is-danger">Job Application not found!</div>;
  }

  const { job_description, cover_letter, notes, created_at, updated_at } = jobApplication;

  const handleDelete = async () => {
    setIsLoading(true);
    if (window.confirm('Are you sure you want to delete this job application?')) {
      try {
        if (jobApplication?.id) {
          await deleteJobApplication(jobApplication.id);
          navigate('/'); // Navigate to the home or list page after deletion
        }
      } catch (error) {
        console.error('Error deleting job application:', error);
        alert('Failed to delete job application.');
      }
    }
    setIsLoading(false);
  };

  const createdAtTag = formatDistanceToNow(new Date(created_at), { addSuffix: true });
  const updatedAtTag = formatDistanceToNow(new Date(updated_at), { addSuffix: true });
  const showUpdatedAtTag = createdAtTag !== updatedAtTag;
  const appliedAtTag = appliedAt ? formatDistanceToNow(appliedAt, { addSuffix: true }) : '';

  return (
    <React.Fragment>
      <Helmet>
        <title>Job Application Details - Jobsy</title>
      </Helmet>

      {/* Title (job name) */}
      <section className="section">
        <div className="container">
          <div className="level is-mobile">
            <div className="level-left">
              <div className="is-flex is-flex-direction-column">
                <h1 className="title is-3">{jobTitle ? jobTitle : 'Unspecified job title'}</h1>

                <p className="subtitle is-6">{companyName}</p>
              </div>
            </div>
            <div className="level-right">
              <div className="field is-grouped">
                <Link className="button is-info is-small" to={'/edit/' + jobApplication.id}>
                  Edit
                </Link>
                <button className="button is-danger is-small" onClick={handleDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>

      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-3 box is-size-7">
            {/* Editable Fields */}

            <h3 className="subtitle is-6 mb-1 mt-0 has-background-light p-3">Job</h3>

            <JAAttribute
              label="Role"
              editableVariant={
                <input
                  type="text"
                  className="input is-small"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                />
              }
              acceptHandler={() => {
                jobApplication.job_title = jobTitle;
                editJobApplication(jobApplication);
              }}
              resetHandler={() => {
                setJobTitle(jobApplication.job_title || '');
              }}
            >
              <p>{jobTitle ? jobTitle : <em className="has-text-warning">N/A</em>}</p>
            </JAAttribute>

            <JAAttribute
              label="URL"
              editableVariant={
                <input
                  type="text"
                  className="input is-small"
                  value={jobUrl}
                  onChange={(e) => setJobUrl(e.target.value)}
                />
              }
              acceptHandler={() => {
                jobApplication.job_url = jobUrl;
                editJobApplication(jobApplication);
              }}
              resetHandler={() => {
                setJobUrl(jobApplication.job_url || '');
              }}
            >
              <p>{jobUrl ? jobUrl : <em className="has-text-warning">N/A</em>}</p>
            </JAAttribute>

            <JAAttribute
              label="Origin"
              editableVariant={
                <input
                  type="text"
                  className="input is-small"
                  value={origin}
                  onChange={(e) => setOrigin(e.target.value)}
                />
              }
              acceptHandler={() => {
                jobApplication.origin = origin;
                editJobApplication(jobApplication);
              }}
              resetHandler={() => {
                setOrigin(jobApplication.origin || '');
              }}
            >
              <p>{origin ? origin : <em className="has-text-warning">N/A</em>}</p>
            </JAAttribute>

            <JAAttribute
              label="State"
              editableVariant={
                <select
                  className="select is-small is-fullwidth"
                  value={state}
                  onChange={(e) => setState(e.target.value as JobApplicationState)}
                >
                  {Object.values(JobApplicationState).map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              }
              acceptHandler={() => {
                jobApplication.state = state;
                editJobApplication(jobApplication);
              }}
              resetHandler={() => {
                setState(jobApplication.state || JobApplicationState.Considering);
              }}
            >
              <p>
                <StateTag state={state} />
              </p>
            </JAAttribute>

            <JAAttribute
              label="Applied"
              editableVariant={
                <DatePicker
                  selected={appliedAt}
                  onChange={(date) => setAppliedAt(date)}
                  className="input is-small"
                  popperPlacement="bottom-end"
                />
              }
              acceptHandler={() => {
                jobApplication.applied_at = appliedAt;
                editJobApplication(jobApplication);
              }}
              resetHandler={() => {
                setAppliedAt(jobApplication.applied_at || new Date());
              }}
            >
              <p className="help">
                {appliedAtTag ? (
                  <span className="is-size-7">{appliedAtTag}</span>
                ) : (
                  <em className="has-text-warning">N/A</em>
                )}
              </p>
            </JAAttribute>

            <JAAttribute
              label="CV"
              editableVariant={
                <input type="text" className="input is-small" value={cv} onChange={(e) => setCv(e.target.value)} />
              }
              acceptHandler={() => {
                jobApplication.cv = cv;
                editJobApplication(jobApplication);
              }}
              resetHandler={() => {
                setCv(jobApplication.cv || '');
              }}
            >
              <p>{cv ? cv : <em className="has-text-warning">N/A</em>}</p>
            </JAAttribute>

            <JAAttribute
              label="Potential"
              editableVariant={
                <select
                  className="select is-small is-fullwidth"
                  value={potential}
                  onChange={(e) => setPotential(e.target.value as JobApplicationPotential)}
                >
                  {Object.values(JobApplicationPotential).map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              }
              acceptHandler={() => {
                jobApplication.potential = potential;
                editJobApplication(jobApplication);
              }}
              resetHandler={() => {
                setPotential(jobApplication.potential || JobApplicationPotential.Low);
              }}
            >
              <p>{potential && <PotentialTag potential={potential} />}</p>
            </JAAttribute>

            <h3 className="subtitle is-6 mb-1 mt-3 has-background-light p-3">Company</h3>

            <JAAttribute
              label="Name"
              editableVariant={
                <input
                  type="text"
                  className="input is-small"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              }
              acceptHandler={() => {
                jobApplication.company_name = companyName;
                editJobApplication(jobApplication);
              }}
              resetHandler={() => {
                setCompanyName(jobApplication.company_name || '');
              }}
            >
              <p>{companyName || <em className="has-text-warning">N/A</em>}</p>
            </JAAttribute>

            <JAAttribute
              label="Website"
              editableVariant={
                <input
                  type="text"
                  className="input is-small"
                  value={companyPublicUrl}
                  onChange={(e) => setCompanyPublicUrl(e.target.value)}
                />
              }
              acceptHandler={() => {
                jobApplication.company_public_url = companyPublicUrl;
                editJobApplication(jobApplication);
              }}
              resetHandler={() => {
                setCompanyPublicUrl(jobApplication.company_public_url || '');
              }}
            >
              <p>
                {companyPublicUrl ? (
                  <a href={companyPublicUrl} target="_blank" rel="noopener noreferrer">
                    {companyPublicUrl}
                  </a>
                ) : (
                  <em className="has-text-warning">N/A</em>
                )}
              </p>
            </JAAttribute>

            <JAAttribute
              label="LinkedIn"
              editableVariant={
                <input
                  type="text"
                  className="input is-small"
                  value={companyLinkedInUrl}
                  onChange={(e) => setCompanyLinkedInUrl(e.target.value)}
                />
              }
              acceptHandler={() => {
                jobApplication.company_linkedin_url = companyLinkedInUrl;
                editJobApplication(jobApplication);
              }}
              resetHandler={() => {
                setCompanyLinkedInUrl(jobApplication.company_linkedin_url || '');
              }}
            >
              <p>
                {companyLinkedInUrl ? (
                  <a href={companyLinkedInUrl} target="_blank" rel="noopener noreferrer">
                    {companyLinkedInUrl}
                  </a>
                ) : (
                  <em className="has-text-warning">N/A</em>
                )}
              </p>
            </JAAttribute>

            <h3 className="subtitle is-6 mb-1 mt-3 has-background-light p-3">Compensation</h3>

            <JAAttribute
              label="Offered"
              editableVariant={
                <input
                  type="text"
                  className="input is-small"
                  value={compensationOffered}
                  onChange={(e) => setCompensationOffered(e.target.value)}
                />
              }
              acceptHandler={() => {
                jobApplication.compensation_offered = compensationOffered;
                editJobApplication(jobApplication);
              }}
              resetHandler={() => {
                setCompensationOffered(jobApplication.compensation_offered || '');
              }}
            >
              <p>
                {compensationOffered ? <span>{compensationOffered}</span> : <em className="has-text-warning	">N/A</em>}
              </p>
            </JAAttribute>

            <JAAttribute
              label="Asked"
              editableVariant={
                <input
                  type="text"
                  className="input is-small"
                  value={compensationAsked}
                  onChange={(e) => setCompensationAsked(e.target.value)}
                />
              }
              acceptHandler={() => {
                jobApplication.compensation_asked = compensationAsked;
                editJobApplication(jobApplication);
              }}
              resetHandler={() => {
                setCompensationAsked(jobApplication.compensation_asked || '');
              }}
            >
              <p>{compensationAsked ? <span>{compensationAsked}</span> : <em className="has-text-warning">N/A</em>}</p>
            </JAAttribute>

            <h3 className="subtitle is-6 mb-1 mt-3 has-background-light p-3">Misc</h3>

            <JAAttribute label="Dates" readOnly={true}>
              <p className="is-size-7" title={created_at?.toLocaleDateString()}>
                Created <span>{createdAtTag}</span>
              </p>
              {showUpdatedAtTag && (
                <p className="is-size-7" title={updated_at?.toLocaleDateString()}>
                  Updated <span>{updatedAtTag}</span>
                </p>
              )}
            </JAAttribute>
          </div>
          <div className="column is-9 pt-0">
            <CollapsibleSection title="Job Description" link={jobUrl}>
              {job_description && <div className="content" dangerouslySetInnerHTML={{ __html: job_description }} />}
            </CollapsibleSection>

            {cover_letter && (
              <CollapsibleSection title="Cover Letter">
                <div className="content" dangerouslySetInnerHTML={{ __html: cover_letter }} />
              </CollapsibleSection>
            )}
            <hr />
            <h3 className="subtitle is-4 mb-1 mt-3">Notes</h3>
            <NotesList jobApplicationId={jobApplication.id!} notes={notes} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default JobApplicationDetails;
