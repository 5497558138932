import { formatDistanceToNow } from 'date-fns';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { JobApplication } from '../../models/JobApplication';
import './JobApplicationsList.scss';
import JobApplicationStats from './JobApplicationStats';
import PotentialTag from './PotentialTag';
import StateTag from './StateTag';

interface JobApplicationsListProps {
  applications: JobApplication[];
}

interface HighlightedNote {
  id: string;
  showEllipsisBefore: boolean;
  beforeText: string;
  matchingTextPrefix: string;
  matchingText: string;
  mathcingTextSuffix: string;
  afterText: string;
  showEllipsisAfter: boolean;
}

function JobApplicationsList({ applications }: JobApplicationsListProps) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedStates, setSelectedStates] = useState<string[]>([]);

  const handleStateChange = (selectedStates: string[]) => {
    setSelectedStates(selectedStates || []);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const splitWord = (word: string, searchTerm: string): { prefix: string; middle: string; suffix: string } => {
    const index = word.toLowerCase().indexOf(searchTerm.toLowerCase());
    if (index === -1) {
      return { prefix: word, middle: '', suffix: '' }; // No match found
    }
    const prefix = word.slice(0, index);
    const middle = word.slice(index, index + searchTerm.length);
    const suffix = word.slice(index + searchTerm.length);
    return { prefix, middle, suffix };
  };

  const filteredApplications = applications
    .filter((app) => {
      const matchesState =
        selectedStates.length === 0 || // No selection means include all
        selectedStates.some((selectedState) => selectedState === app.state);
      const matchesSearch =
        app.company_name.toLowerCase().includes(searchTerm) ||
        app.notes.filter((note) => note.content.toLowerCase().includes(searchTerm)).length > 0;
      return matchesState && matchesSearch;
    })
    .map((app) => {
      if (
        searchTerm &&
        app.notes &&
        app.notes.filter((note) => note.content.toLowerCase().includes(searchTerm)).length > 0
      ) {
        const highlightedNotes: HighlightedNote[] = app.notes
          .filter((note) => note.content.toLowerCase().includes(searchTerm))
          .map((note) => {
            const words = note.content.split(/\s+/);
            const index = words.findIndex((word) => word.toLowerCase().includes(searchTerm));
            const numberOfSideWords = 2;

            const beforeText = words.slice(Math.max(0, index - numberOfSideWords), index).join(' ');
            const matchingText = words[index];
            const afterText = words.slice(Math.max(0, index + 1), index + numberOfSideWords + 1).join(' ');

            const splitMatchingText = splitWord(matchingText, searchTerm);

            const result: HighlightedNote = {
              id: note.id!,
              showEllipsisBefore: index > 0,
              beforeText: `${beforeText} `,
              matchingTextPrefix: splitMatchingText.prefix,
              matchingText: splitMatchingText.middle,
              mathcingTextSuffix: splitMatchingText.suffix,
              afterText: ` ${afterText}`,
              showEllipsisAfter: index < words.length - 1,
            };

            return result;
          });
        return { ...app, highlightedNotes: highlightedNotes } as JobApplication & {
          highlightedNotes: HighlightedNote[];
        };
      }
      // add noteSnippet field to the model for display purposes
      return app as JobApplication & { highlightedNotes: HighlightedNote[] };
    });

  return (
    <div className="job-application-list-container">
      <div className="mb-5">
        <JobApplicationStats jobApplications={applications} onSelectedStatesChange={handleStateChange} />
      </div>
      {/*
        MARK: Filters
      */}
      <div className="columns has-background-light">
        {/* <div className="column">
          <h3 className="title is-5 mb-2">Filters</h3>
        </div> */}
        <div className="column">
          <div className="field">
            <div className="control has-icons-right">
              <input
                className="input"
                type="text"
                placeholder="Search by company name or notes"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              {searchTerm && (
                <button
                  className="delete is-small clear-icon"
                  onClick={() => setSearchTerm('')}
                  aria-label="Clear search"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/*
        MARK: Job Applications Table
      */}
      <div className="table-container">
        <table className="table is-fullwidth is-narrow">
          <thead>
            <tr key="table-header">
              <th>Role</th>
              <th>State</th>
              <th>Last updated</th>
              <th>Potential</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {filteredApplications.map((item) => (
              <React.Fragment key={item.id}>
                <tr className={item.highlightedNotes ? 'no-bottom-border' : ''}>
                  <td>
                    <Link to={'details/' + item.id} className="has-text-weight-semibold">
                      {item.job_title?.length && item.job_title?.length > 0 ? item.job_title : 'Job'}
                    </Link>
                    &nbsp;at&nbsp;
                    <strong>{item.company_name}</strong>
                  </td>
                  <td>{item.state && <StateTag state={item.state} />}</td>
                  <td title={item.updated_at?.toLocaleDateString() + ' ' + item.updated_at?.toLocaleTimeString()}>
                    {formatDistanceToNow(new Date(item.updated_at), { addSuffix: true })}
                  </td>
                  <td>{item.potential && <PotentialTag potential={item.potential} />}</td>
                  <td>
                    <Link to={`/edit/${item.id}`} className="button is-info is-small is-text">
                      <span className="icon">
                        <i className="fas fa-pencil"></i>
                      </span>
                    </Link>
                  </td>
                </tr>
                {item.highlightedNotes && (
                  <tr>
                    <td colSpan={1}>
                      <i>Notes:</i>
                    </td>
                    <td colSpan={4}>
                      <div className="highlighted-notes-container tags">
                        {item.highlightedNotes?.map((highlightedNote) => (
                          <React.Fragment key={highlightedNote.id}>
                            <span className="highlighted-note">
                              {highlightedNote.showEllipsisBefore && <span>...</span>}
                              <span>{highlightedNote.beforeText}</span>
                              <span>{highlightedNote.matchingTextPrefix}</span>
                              <span className="highlight">{highlightedNote.matchingText}</span>
                              <span>{highlightedNote.mathcingTextSuffix}</span>
                              <span>{highlightedNote.afterText}</span>
                              {highlightedNote.showEllipsisAfter && <span>...</span>}
                            </span>
                          </React.Fragment>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default JobApplicationsList;
