import { useEffect, useState } from 'react';

// Define Bulma breakpoints in pixels
const bulmaBreakpoints = {
  mobile: 768,
  tablet: 1024,
  desktop: 1216,
  widescreen: 1408,
};

function useBulmaBreakpoint() {
  const [breakpoint, setBreakpoint] = useState<'mobile' | 'tablet' | 'desktop' | 'widescreen'>('mobile');

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const updateBreakpoint = () => {
      const width = window.innerWidth;

      if (width < bulmaBreakpoints.mobile) {
        setBreakpoint('mobile');
      } else if (width < bulmaBreakpoints.tablet) {
        setBreakpoint('tablet');
      } else if (width < bulmaBreakpoints.desktop) {
        setBreakpoint('desktop');
      } else {
        setBreakpoint('widescreen');
      }
    };

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(updateBreakpoint, 150); // Debounce delay in milliseconds
    };

    // Set initial breakpoint
    updateBreakpoint();

    // Update on resize
    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return breakpoint;
}

export default useBulmaBreakpoint;
