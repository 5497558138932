import { useEffect, useState } from 'react';

export const useExtensionPing = (): boolean | null => {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState<boolean | null>(null);

  const chromeExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
  const chromeExtensionLink = process.env.REACT_APP_CHROME_EXTENSION_LINK;

  useEffect(() => {
    const pingExtension = () => {
      if (!chromeExtensionLink) {
        console.log('Chrome extension not available yet!');
        return;
      }

      try {
        chrome.runtime.sendMessage(chromeExtensionId, { type: 'PING' }, (response) => {
          if (chrome.runtime.lastError || !response) {
            setIsExtensionInstalled(false);
          } else {
            setIsExtensionInstalled(true);
          }
        });
      } catch (error) {
        setIsExtensionInstalled(false);
      }
    };

    pingExtension();
  }, [chromeExtensionId, chromeExtensionLink]);

  return isExtensionInstalled;
};
