import React, { useState } from 'react';
import { JobApplicationState } from '../../../models/Enums';
import { set } from 'date-fns';

interface JAAttributeProps {
  label: string;
  readOnly?: boolean;
  children: React.ReactNode;
  editableVariant?: React.ReactNode;
  acceptHandler?: () => void;
  resetHandler?: () => void;
}

const JAAttribute: React.FC<JAAttributeProps> = ({
  label,
  readOnly,
  children,
  editableVariant,
  acceptHandler,
  resetHandler,
}) => {
  const [inEditMode, setInEditMode] = useState<boolean>(false);

  return (
    <div className="column is-12">
      <div className="columns is-mobile">
        <div className="column is-4">
          <p className="has-text-weight-bold">{label}</p>
        </div>
        {!inEditMode && (
          <div
            className="column"
            style={{
              border: '1px dashed transparent',
              overflow: 'hidden',
              borderRadius: '5px',
              position: 'relative',
            }}
            onClick={() => {
              if (readOnly) return;
              setInEditMode(true);
            }}
            onMouseEnter={(e) => {
              if (readOnly) return;
              e.currentTarget.style.border = '1px dashed #999';
              e.currentTarget.style.cursor = 'pointer';
              const icon = e.currentTarget.querySelector('.icon') as HTMLElement;
              if (icon) icon.style.visibility = 'visible';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.border = '1px dashed transparent';
              const icon = e.currentTarget.querySelector('.icon') as HTMLElement;
              if (icon) icon.style.visibility = 'hidden';
            }}
          >
            <span className="icon" style={{ float: 'right', visibility: 'hidden' }}>
              <i className="fa-solid fa-pencil"></i>
            </span>
            {children}
          </div>
        )}

        {inEditMode && (
          <React.Fragment>
            <div
              className="column is-flex is-align-items-center"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (acceptHandler) {
                    acceptHandler();
                  }
                  setInEditMode(false);
                }
                if (e.key === 'Escape') {
                  if (resetHandler) {
                    resetHandler();
                  }
                  setInEditMode(false);
                }
              }}
            >
              {editableVariant}
              <button
                className="button is-small is-gray mr-2 ml-2"
                onClick={() => {
                  if (acceptHandler) {
                    setInEditMode(false);
                    acceptHandler();
                  }
                }}
              >
                <span className="icon">
                  <i className="fa-solid fa-check"></i>
                </span>
              </button>
              <button
                className="button is-small is-gray"
                onClick={() => {
                  setInEditMode(false);
                  if (resetHandler) {
                    resetHandler();
                  }
                }}
              >
                <span className="icon">
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default JAAttribute;
