import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { createUserEmailAndPassword } from '../../services/AuthenticationService';

const SignUp: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      await createUserEmailAndPassword(email, password);
      setSuccessMessage('Account created successfully! You can now log in.');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setError('');
      navigate('/');
    } catch (err: any) {
      setError(err.message || 'An error occurred while signing up.');
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Sign Up - Jobsy</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="container is-fluid mt-6">
            <div className="columns is-centered">
              <div className="column is-half">
                <div className="box">
                  <h2 className="title is-4 has-text-centered">Sign Up</h2>
                  {error && <p className="notification is-danger">{error}</p>}
                  {successMessage && <p className="notification is-success">{successMessage}</p>}
                  <form onSubmit={handleSignUp}>
                    <div className="field">
                      <label className="label">Email</label>
                      <div className="control has-icons-left">
                        <input
                          className="input"
                          type="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Password</label>
                      <div className="control has-icons-left">
                        <input
                          className="input"
                          type="password"
                          placeholder="Enter your password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-lock"></i>
                        </span>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Confirm Password</label>
                      <div className="control has-icons-left">
                        <input
                          className="input"
                          type="password"
                          placeholder="Confirm your password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-check"></i>
                        </span>
                      </div>
                    </div>

                    <div className="field">
                      <div className="control">
                        <button type="submit" className="button is-primary is-fullwidth">
                          Sign Up
                        </button>
                      </div>
                    </div>
                    <p className="has-text-centered">
                      Already have an account?{' '}
                      <Link to="/signin" className="has-text-primary">
                        Sign in here
                      </Link>
                      .
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SignUp;
