import React, { useState } from 'react';
import { Note } from '../../models/Note';
import NoteDisplay from './NoteDisplay';
import NoteForm from './NoteForm';
import './NotesList.scss';

interface NotesListProps {
  jobApplicationId: string;
  notes: Note[];
}

function NotesList({ jobApplicationId, notes }: NotesListProps) {
  const [internalNotes, setInternalNotes] = useState<Note[]>(notes);
  const [noteToEdit, setNoteToEdit] = useState<Note | null>(null);

  const displayedNotes: Note[] = internalNotes
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

  const handleAddNote = (newNote: Note) => {
    setInternalNotes((previousInternalNotes) => [...previousInternalNotes, newNote]);
    notes.push(newNote);
  };

  const handleEditNote = (editedNote: Note) => {
    setNoteToEdit(null);
  };

  return (
    <React.Fragment>

      <div className="note-form-container">
        <NoteForm
          jobApplicationId={jobApplicationId}
          onAdd={handleAddNote}
        />
      </div>
      <hr />
      {/*
          MARK: Notes List
        */}
      <div className="display-notes-container">
        {displayedNotes?.map((item) => (
          <React.Fragment key={item.id}>
            {item.id === noteToEdit?.id && <NoteForm
              jobApplicationId={jobApplicationId}
              noteToEdit={noteToEdit}
              onEdit={handleEditNote}
              onCancel={() => setNoteToEdit(null)}
            />}
            {item.id !== noteToEdit?.id && <NoteDisplay
              key={item.id}
              isEditMode={item.id === noteToEdit?.id}
              note={item}
              onEdit={(note) => setNoteToEdit(note)}
            />
            }
          </React.Fragment>
        ))}
      </div>

    </React.Fragment >
  );
}

export default NotesList;
