import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { useEffect, useState } from 'react';

interface SendTokenStatus {
  status: 'loading' | 'success' | 'error' | null;
  error?: string;
}

export const useExtensionFirebaseTokenSend = (): SendTokenStatus => {
  const [status, setStatus] = useState<SendTokenStatus>({ status: null });

  const chromeExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
  const chromeExtensionLink = process.env.REACT_APP_CHROME_EXTENSION_LINK;

  useEffect(() => {
    const sendTokenToExtension = async (token: string) => {
      setStatus({ status: 'loading' });

      if (!chromeExtensionLink) {
        console.log('Chrome extension not available yet!');
        return;
      }

      try {
        chrome.runtime.sendMessage(chromeExtensionId, { type: 'FIREBASE_TOKEN', token }, (response) => {
          if (chrome.runtime.lastError || !response?.success) {
            setStatus({
              status: 'error',
              error: chrome.runtime.lastError?.message || 'Failed to send token',
            });
          } else {
            setStatus({ status: 'success' });
          }
        });
      } catch (error: any) {
        console.log('Error contacting extension!');
        setStatus({
          status: 'error',
          error: 'Failed to send token',
        });
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user: User | null) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          sendTokenToExtension(token);
        } catch (error: any) {
          setStatus({ status: 'error', error: error.message });
        }
      }
    });

    return () => unsubscribe();
  }, [chromeExtensionId, chromeExtensionLink]);

  return status;
};
