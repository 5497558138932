import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { NoteType } from '../../models/Enums';
import { Note } from '../../models/Note';
import { addJobApplicationNote, editJobApplicationNote } from '../../services/DatabaseService';
import './NoteForm.scss';

interface NoteFormProps {
  jobApplicationId: string;
  noteToEdit?: Note | null;
  onAdd?: (newNote: Note) => void;
  onEdit?: (editedNote: Note) => void;
  onCancel?: () => void;
}

function NoteForm({ jobApplicationId, noteToEdit = null, onAdd, onEdit, onCancel }: NoteFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [note, setNote] = useState<string>(noteToEdit?.content || '');
  const [noteType, setNoteType] = useState<NoteType>(noteToEdit?.type || NoteType.Misc);
  const availableNoteTypes = Object.values(NoteType);

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (noteToEdit) {
        noteToEdit.content = note;
        noteToEdit.type = noteType;
        noteToEdit.updated_at = new Date();
        await editJobApplicationNote(jobApplicationId, noteToEdit);
        onEdit?.(noteToEdit);
      } else {
        // adding a new application
        const newNote = await addJobApplicationNote(jobApplicationId, {
          type: noteType,
          content: note,
          created_at: new Date(),
          updated_at: new Date(),
        });
        onAdd?.(newNote);
      }
      resetForm();
    } catch (error) {
      console.error('Error adding job application to Firestore:', error);
    }
    setIsLoading(false);
  };

  const handleFormCancel = (event: React.FormEvent) => {
    resetForm();
    onCancel?.();
  };

  const resetForm = () => {
    setNote('');
    setNoteType(NoteType.Misc);
  };

  return (
    <form onSubmit={handleFormSubmit} onReset={handleFormCancel} className="note-edit-form">
      <div className="field">
        {!noteToEdit && <label className="label">Add new note</label>}
        <div className="control">
          <ReactQuill theme="snow" value={note} onChange={setNote} />
        </div>
      </div>
      <div className="field is-grouped is-flex-wrap-wrap">
        <div className="control">
          <div className="select">
            <select value={noteType} onChange={(e) => setNoteType(e.target.value as NoteType)}>
              {availableNoteTypes.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="buttons">
          <button
            type="submit"
            className={`button is-success ${isLoading ? 'is-loading' : ''}`}
            disabled={isLoading || !note}
          >
            Save
          </button>
          <button
            type="reset"
            className={`button is-light ${isLoading ? 'is-loading' : ''}`}
            disabled={isLoading || !note}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
}

export default NoteForm;
