import { getAuth } from 'firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import { db } from '../firebase';
import { JobApplication } from '../models/JobApplication';
import { Note } from '../models/Note';

// --------------------------------------------------------
//
// MARK: Job Applications
//
// --------------------------------------------------------

const getAllJobApplications = async (): Promise<JobApplication[]> => {
  const authenticationData = getAuth();
  if (authenticationData.currentUser) {
    const jobApplicationsQuery = query(
      collection(db, `users/${authenticationData.currentUser.uid}/job_applications`),
      orderBy('updated_at', 'desc')
    );

    const querySnapshot = await getDocs(jobApplicationsQuery);

    const data = querySnapshot.docs.map(async (doc) => {
      const data = doc.data() as JobApplication;
      data.notes = await getAllJobApplicationNotes(doc.id);
      return { id: doc.id, ...convertTimestamps(data) } as JobApplication;
    });

    return await Promise.all(data);
  }
  return [];
};

const getJobApplication = async (id: string): Promise<JobApplication | null> => {
  try {
    const authenticationData = getAuth();
    if (!authenticationData.currentUser) {
      return null;
    }
    const docRef = doc(db, 'users', authenticationData.currentUser.uid, 'job_applications', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      data.notes = await getAllJobApplicationNotes(docSnap.id);
      return { id: docSnap.id, ...convertTimestamps(data) } as JobApplication;
    } else {
      console.error('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching job application:', error);
    throw error;
  }
};

const addJobApplication = async (jobApplication: JobApplication): Promise<JobApplication> => {
  const authenticationData = getAuth();
  if (authenticationData.currentUser) {
    const docRef = await addDoc(
      collection(db, 'users/' + authenticationData.currentUser.uid + '/job_applications'),
      jobApplication
    );

    // Return the created object with the ID
    return { ...jobApplication, id: docRef.id };
  }

  throw new Error('User not authenticated');
};

const addJobApplications = async (jobApplications: JobApplication[]): Promise<JobApplication[]> => {
  const authenticationData = getAuth();

  if (!authenticationData.currentUser) {
    throw new Error('User not authenticated');
  }

  const userJobApplicationsRef = collection(db, `users/${authenticationData.currentUser.uid}/job_applications`);

  const batch = writeBatch(db); // Batch for main job application documents
  const createdApplications: JobApplication[] = [];

  for (const jobApplication of jobApplications) {
    const docRef = doc(userJobApplicationsRef); // Create a new document reference for the job application
    const { notes, ...jobAppData } = jobApplication; // Separate notes from main data
    const jobWithEmptyNotes = { ...jobAppData, notes: [] };

    batch.set(docRef, jobAppData); // Add main job application data to the batch
    createdApplications.push({ ...jobWithEmptyNotes, id: docRef.id });

    // Insert notes as subcollection if they exist
    if (notes && notes.length > 0) {
      const notesRef = collection(docRef, 'notes');
      for (const note of notes) {
        const noteRef = doc(notesRef); // Generate a unique note document ID
        await setDoc(noteRef, note); // Add each note to the subcollection
      }
    }
  }

  await batch.commit(); // Commit the batch for the main job applications
  return createdApplications; // Return all created job applications with their IDs
};

const deleteJobApplication = async (id: string): Promise<void> => {
  try {
    const authenticationData = getAuth();
    if (authenticationData.currentUser) {
      const docRef = doc(db, 'users/' + authenticationData.currentUser.uid + '/job_applications', id);
      await deleteDoc(docRef);
      // Recursively delete subcollections
      // TODO: Find if we can optimize this instead of making a call to delete each note
      const docNotesRef = collection(
        db,
        'users/' + authenticationData.currentUser.uid + '/job_applications/' + id + '/notes'
      );
      for (const note of (await getDocs(docNotesRef)).docs) {
        const noteRef = doc(
          db,
          'users/' + authenticationData.currentUser.uid + '/job_applications/' + id + '/notes',
          note.id
        );
        await deleteDoc(noteRef);
      }
    }
  } catch (error) {
    console.error('Error deleting job application:', error);
    throw error;
  }
};

const editJobApplication = async (jobApplication: JobApplication) => {
  const authenticationData = getAuth();
  if (authenticationData.currentUser) {
    await setDoc(
      doc(db, 'users/' + authenticationData.currentUser.uid + '/job_applications/' + jobApplication.id),
      jobApplication
    );
  }
};

const touchJobApplication = async (id: string) => {
  const authenticationData = getAuth();
  if (authenticationData.currentUser) {
    const docRef = doc(db, 'users/' + authenticationData.currentUser.uid + '/job_applications', id);

    // Update the timestamp field with the value from the server
    const updatedResponse = await updateDoc(docRef, {
      updated_at: serverTimestamp(),
    });

    return updatedResponse;
  }
};

// --------------------------------------------------------
//
// MARK: Notes
//
// --------------------------------------------------------

const getAllJobApplicationNotes = async (id: String): Promise<Note[]> => {
  const authenticationData = getAuth();
  if (authenticationData.currentUser) {
    const querySnapshot = await getDocs(
      collection(db, 'users/' + authenticationData.currentUser.uid + '/job_applications/' + id + '/notes')
    );

    const data: Note[] = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return { id: doc.id, ...data } as Note;
    });

    return data;
  }
  return [];
};

const addJobApplicationNote = async (jobApplicationId: string, note: Note): Promise<Note> => {
  const authenticationData = getAuth();
  if (authenticationData.currentUser) {
    const noteDocRef = await addDoc(
      collection(
        db,
        'users/' + authenticationData.currentUser.uid + '/job_applications/' + jobApplicationId + '/notes'
      ),
      note
    );
    await touchJobApplication(jobApplicationId);
    return { ...note, id: noteDocRef.id };
  }
  throw new Error('User not authenticated');
};

const editJobApplicationNote = async (jobApplicationId: string, note: Note) => {
  const authenticationData = getAuth();
  if (authenticationData.currentUser) {
    await setDoc(
      doc(
        db,
        'users/' + authenticationData.currentUser.uid + '/job_applications/' + jobApplicationId + '/notes/' + note.id
      ),
      note
    );
  }
};

// --------------------------------------------------------
//
// MARK: Utilities
//
// --------------------------------------------------------

// Utility function to recursively convert Timestamp fields to Date
const convertTimestamps = <T>(data: T): T => {
  if (data && typeof data === 'object') {
    // If the object is an array, map through it
    if (Array.isArray(data)) {
      return data.map((item) => convertTimestamps(item)) as unknown as T;
    }

    // If the object is not an array, process its keys
    return Object.keys(data).reduce((acc, key) => {
      const value = (data as any)[key];

      // Ensure acc is typed as the same type as data
      acc[key as keyof T] =
        value instanceof Timestamp
          ? value.toDate()
          : value && typeof value === 'object'
          ? convertTimestamps(value)
          : value;

      return acc;
    }, {} as T); // Explicitly typing acc as T
  }

  // Return the original value if it's not an object
  return data;
};

export {
  addJobApplication,
  addJobApplications,
  addJobApplicationNote,
  deleteJobApplication,
  editJobApplication,
  editJobApplicationNote,
  getAllJobApplicationNotes,
  getAllJobApplications,
  getJobApplication,
};
