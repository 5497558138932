import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Cookies: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Cookies - Jobsy</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="docs-wrapper">
            <h1 className="title is-1">Cookie policy</h1>
            <div className="docs-container content">
              <h2 id="thegist" className="title is-2">
                The Gist
              </h2>
              <p>
                Our <Link to="/privacy-policy">Privacy Policy</Link> explains our principles when it comes to the collection,
                processing, and storage of your information. This
                policy specifically explains how we, our partners, and users of our services deploy cookies, as well as the
                options you have to control them.
              </p>
              <h3 id="whatarecookies" className="title is-3">
                What are cookies?
              </h3>
              <p>
                Cookies are small pieces of data, stored in text files, that are stored on your computer or other device
                when websites are loaded in a browser. They are widely used to "remember" you and your preferences, either
                for a single visit (through a "session cookie") or for multiple repeat visits (using a "persistent cookie").
                They ensure a consistent and efficient experience for visitors, and perform essential functions such as
                allowing users to register and remain logged in. Cookies may be set by the site that you are visiting (known
                as "first party cookies"), or by third parties, such as those who serve content or provide advertising or
                analytics services on the website ("third party cookies"). Both websites and HTML emails may also contain
                other tracking technologies such as "web beacons" or "pixels." These are typically small transparent images
                that provide us with statistics, for similar purposes as cookies. They are often used in conjunction with
                cookies, though they are not stored on your computer in the same way. As a result, if you disable cookies,
                web beacons may still load, but their functionality will be restricted.
              </p>
              <h3 id="howweusecookies" className="title is-3">
                <strong>How we use cookies</strong>
              </h3>
              <p>
                We use cookies for a number of different purposes. Some cookies are necessary for technical reasons; some
                enable a personalized experience for both visitors and registered users; and some allow the display of
                advertising from selected third party networks. Some of these cookies may be set when a page is loaded, or
                when a visitor takes a particular action (clicking the "like" or "follow" button on a post, for example).
                Many of the cookies we use are only set if you are a registered user in one of our Services (so you don’t
                have to log in every time, for example), while others are set whenever you visit one of our Services,
                irrespective of whether you have an account. For more information on the choices you have about the cookies
                we use, please see the Controlling Cookies section below.
              </p>
              <h3 id="whereweplacecookies" className="title is-3">
                <strong>Where we place cookies</strong>
              </h3>
              <p> We set cookies in a number of different locations across our services. These include: </p>
              <ul>
                <li>On our websites.</li>
                <li>In the administrative dashboards of our websites.</li>
              </ul>
              <h3 id="typesofcookie" className="title is-3">
                <strong>Types of Cookie</strong>
              </h3>
              <p> The table below explains the types of cookies we use on our websites and why we use them. </p>
              <table>
                <thead>
                  <tr>
                    <th>Category of cookies</th>
                    <th>Why we use these cookies</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Strictly Necessary</td>
                    <td>
                      These cookies are essential for websites on our services to perform their basic functions. These
                      include those required to allow registered users to authenticate and perform account related
                      functions.
                    </td>
                  </tr>
                  <tr>
                    <td>Functionality</td>
                    <td>
                      These cookies are used to store preferences set by users such as account name, language, theme and
                      location.
                    </td>
                  </tr>
                  <tr>
                    <td>Security</td>
                    <td> We use these cookies to help identify and prevent potential security risks. </td>
                  </tr>
                  <tr>
                    <td>Analytics and Performance</td>
                    <td>
                      Performance cookies collect information on how users interact with our websites, including what pages
                      are visited most, as well as other analytical data. We use these details to improve how our websites
                      function and to understand how users interact with them.
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3 id="examples" className="title is-3">
                Examples
              </h3>
              <p>
                Below are examples of the cookies set by Jobsy.Works, with explanations of their purpose. Please note that
                this is not an exhaustive list, but rather aims to be representative.
              </p>
              <h4 id="functionality" className="title is-4">
                <strong>Functionality</strong>
              </h4>
              <table>
                <thead>
                  <tr>
                    <th>Cookie</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>access_token</td>
                    <td>
                      A token used to authenticate you, so that you don't have to input your username and password on each
                      action.
                    </td>
                  </tr>
                  <tr>
                    <td>current_user</td>
                    <td> Details about your account that we use to make your experience better. </td>
                  </tr>
                </tbody>
              </table>
              <h4 id="eucookielawbanner" className="title is-4">
                <strong>EU Cookie Law Banner</strong>
              </h4>
              <table>
                <thead>
                  <tr>
                    <th>Cookie Name</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ACCEPTED_COOKIES</td>
                    <td> This one tells us if you accepted our cookies, so that we don't bother you by asking again. </td>
                  </tr>
                </tbody>
              </table>
              <h4 id="analytics" className="title is-4">
                <strong>Analytics</strong>
              </h4>
              <table>
                <thead>
                  <tr>
                    <th>Cookie</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>_ga</td>
                    <td>Used by Google Analytics to distinguish users.</td>
                  </tr>
                  <tr>
                    <td>_gid</td>
                    <td>Used by Google Analytics to distinguish users.</td>
                  </tr>
                  <tr>
                    <td>_gat</td>
                    <td>Used by Google Analytics to throttle request rate.</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Please note that this section is not intended to be exhaustive, but rather aims to be representative, and
                that our users may set additional cookies (such as Google Analytics), depending on the features they have
                chosen to enable or the plugins they have installed.
              </p>
              <h3 id="controllingcookies" className="title is-3">
                Controlling Cookies
              </h3>
              <p>
                Visitors may wish to restrict the use of cookies or completely prevent them from being set. Most browsers
                provide for ways to control cookie behavior such as the length of time they are stored – either through
                built-in functionality or by utilizing third party plugins. If you disable cookies, please be aware that
                some of the features of our service may not function correctly. To find out more on how to manage and delete
                cookies, visit aboutcookies.org. For more details on your choices regarding use of your web browsing
                activity for interest-based advertising visit <a href="http://youronlinechoices.eu">youronlinechoices.eu</a> (EU based)
                or <a href="http://optout.aboutads.info">optout.aboutads.info</a> (US based).
              </p>
              <h3 id="contactus" className="title is-3">
                Contact Us
              </h3>
              <p>
                If you have any questions about our use of cookies, you can find the most relevant contact details in our <Link to="/privacy-policy">Privacy Policy</Link>.
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Cookies;
