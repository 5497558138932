/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CsvImporter from '../../components/common/CsvImporter';
import FieldMapper from '../../components/common/FieldMapper';
import { JobApplication } from '../../models/JobApplication';
import './DataImport.scss';
import JobApplicationsDetailedList from '../../components/job-applications/JobApplicationsDetailedList';
import { addJobApplications } from '../../services/DatabaseService';
import { useNavigate } from 'react-router-dom';

enum ImportStep {
    Upload = 'upload',
    Mapping = 'mapping',
    Save = 'save',
}

const DataImport: React.FC = () => {
    const [parsedData, setParsedData] = useState<any[] | null>(null);
    const [mappedData, setMappedData] = useState<JobApplication[]>([]);
    const [step, setStep] = useState<ImportStep>(ImportStep.Upload);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleParsedData = (data: any[] | null) => {
        setParsedData(data);
        setMappedData([]);
    };

    // This function will be called when the data is mapped
    const handleDataMapped = (mappedData: JobApplication[]) => {
        setMappedData(mappedData);
    };

    const handleImport = async () => {
        setIsLoading(true);
        try {
            await addJobApplications(mappedData);
            navigate('/');
        }
        catch (error: any) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const handleContinue = async () => {
        setStep((prevStep) => {
            switch (prevStep) {
                case ImportStep.Upload:
                    return ImportStep.Mapping;
                case ImportStep.Mapping:
                    return ImportStep.Save;
                default:
                    console.log('Mapped data: ', mappedData);
                    return prevStep; // If on the last step, do nothing
            }
        });
    };

    const handleGoBack = () => {
        setStep((prevStep) => {
            switch (prevStep) {
                case ImportStep.Mapping:
                    return ImportStep.Upload;
                case ImportStep.Save:
                    return ImportStep.Mapping;
                default:
                    return prevStep; // If on the first step, do nothing
            }
        });
    };

    const isContinueDisabled = () => {
        return !parsedData || (!mappedData?.length && (step === ImportStep.Mapping || step === ImportStep.Save));
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Data import - Jobsy</title>
            </Helmet>
            <section className='section data-import-section'>

                <div className="container">
                    <h1 className="title">Import Job Applications</h1>
                    <p className='subtitle is-5'>Experimental, try it with a couple of rows first</p>

                    <div className="tabs is-centered is-fullwidth">
                        <ul>
                            <li className={step === ImportStep.Upload ? 'is-active' : ''}>
                                <a>
                                    <span className="icon is-small">
                                        <i className="fas fa-upload" aria-hidden="true" />
                                    </span>
                                    <span>File upload</span>
                                </a>
                            </li>
                            <li className={step === ImportStep.Mapping ? 'is-active' : ''}>
                                <a>
                                    <span className="icon is-small">
                                        <i className="fas fa-gear" />
                                    </span>
                                    <span>Field mapping</span>
                                </a>
                            </li>
                            <li className={step === ImportStep.Save ? 'is-active' : ''}>
                                <a>
                                    <span className="icon is-small">
                                        <i className="fas fa-database" aria-hidden="true" />
                                    </span>
                                    <span>Preview</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={`container step ${step === ImportStep.Upload ? 'is-active' : ''}`}>
                        <CsvImporter onDataParsed={handleParsedData} />
                    </div>
                    {parsedData && <div className={`container step ${step === ImportStep.Mapping ? 'is-active' : ''}`}>
                        <FieldMapper csvData={parsedData} onDataMapped={handleDataMapped} />
                    </div>
                    }
                    <div className={`container step ${step === ImportStep.Save ? 'is-active' : ''}`}>
                        {mappedData?.length > 0 && <JobApplicationsDetailedList data={mappedData} />}
                    </div>
                    <hr />
                    <div className='buttons'>
                        {step !== ImportStep.Upload && <button className='button is-link is-outlined' onClick={handleGoBack}>Go back</button>}
                        {step !== ImportStep.Save && <button className='button is-link' onClick={handleContinue} disabled={isContinueDisabled()}>Continue</button>}
                        {step === ImportStep.Save && <button disabled={isLoading} className={`button is-link ${isLoading ? 'is-loading' : ''}`} onClick={handleImport}>Import</button>}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default DataImport;