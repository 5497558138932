import React, { useState } from 'react';
import './CollapsibleSection.scss';
import { isNoSubstitutionTemplateLiteral } from 'typescript';

interface CollapsibleSectionProps {
  title: string;
  link?: string;
  children: React.ReactNode;
}

function CollapsibleSection({ title, link, children }: CollapsibleSectionProps) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="collapsible-section-container box">
      <div className="level is-mobile">
        <div className="level-left">
          <p className="is-size-6 has-text-weight-medium">
            {title}
            {link && (
              <a className="ml-4" href={link} target="_blank" rel="noopener noreferrer">
                {link}
              </a>
            )}
          </p>
        </div>
        <div className="level-right">
          <button className={`button is-small is-gray`} onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <div>
                Collapse
                <span className="icon ml-1">
                  <i className="fa-solid fa-chevron-up"></i>
                </span>
              </div>
            ) : (
              <div>
                Expand
                <span className="icon ml-1">
                  <i className="fa-solid fa-chevron-down"></i>
                </span>
              </div>
            )}
          </button>
        </div>
      </div>

      {isOpen && <div className="content">{children ? children : <em className="has-text-grey">N/A</em>}</div>}
    </div>
  );
}

export default CollapsibleSection;
