import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import React from 'react';
import { Note } from '../../models/Note';
import './NoteDisplay.scss';

interface NoteDisplayProps {
  note: Note;
  onEdit?: (note: Note) => void;
  isEditMode: boolean;
}

const NoteDisplay: React.FC<NoteDisplayProps> = ({ note, onEdit, isEditMode }) => {
  const createdAtTag = formatDistanceToNow(new Date(note.created_at), { addSuffix: true, }).replace('about ', '');
  const updatedAtTag = formatDistanceToNow(new Date(note.updated_at), { addSuffix: true }).replace('about ', '');
  const showUpdatedAtTag = createdAtTag !== updatedAtTag;

  return (
    <article className={`message is-small is-dark ${isEditMode ? 'is-warning' : ''}`}>
      <div className="message-body" >
        <div className='corner-top-right has-text-weight-light'>
          <div className='level is-mobile'>
            {!showUpdatedAtTag && <div>Created {createdAtTag}</div>}
            {showUpdatedAtTag && <div>Updated {createdAtTag}</div>}
            {onEdit && (
              <button className="button is-small is-text" aria-label="delete" onClick={() => onEdit(note)}>
                <span className="icon">
                  <i className="fas fa-pencil"></i>
                </span>
              </button>
            )}
          </div>
        </div>
        <div className="note-contents" dangerouslySetInnerHTML={{ __html: note.content }}></div>
        <div className="corner-bottom-right has-text-weight-light">
          {note.type}
        </div>
      </div>
    </article>
  );
};

export default NoteDisplay;
