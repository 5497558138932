// src/firebase.ts
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

// Your Firebase config object (from Firebase Console)
const firebaseConfigProd = {
  apiKey: 'AIzaSyCsKm2COtWZqCtV2Ul5QHOmz1trcBFyJFw',
  authDomain: 'jobsy-7acc6.firebaseapp.com',
  projectId: 'jobsy-7acc6',
  storageBucket: 'jobsy-7acc6.firebasestorage.app',
  messagingSenderId: '999938769505',
  appId: '1:999938769505:web:97ef9e24f6d910d3ab2020',
  measurementId: 'G-Z39QBBNHZK',
};

const firebaseConfigDev = {
  apiKey: 'AIzaSyBpCa1Jzx2GVP8bbsApHUcy8cYtKKjWsi4',
  authDomain: 'jobsy-dev-fba69.firebaseapp.com',
  projectId: 'jobsy-dev-fba69',
  storageBucket: 'jobsy-dev-fba69.firebasestorage.app',
  messagingSenderId: '951027064996',
  appId: '1:951027064996:web:3493203fda38cfdf238631',
  measurementId: 'G-EXEKBDKTS3',
};

const firebaseConfig = process.env.NODE_ENV === 'production' ? firebaseConfigProd : firebaseConfigDev;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

const analytics = getAnalytics(app);

const auth = getAuth(app);

export { app, db, analytics, auth };
