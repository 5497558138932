import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Navbar from './components/top-level/Navbar';
import ProtectedRoute from './components/top-level/ProtectedRoute';
import ChromeExtension from './pages/private/ChromeExtension';
import HomePage from './pages/private/HomePage';
import JobApplicationDetails from './pages/private/JobApplicationDetails';
import JobApplicationEdit from './pages/private/JobApplicationEdit';
import SignIn from './pages/private/SignIn';
import SignUp from './pages/private/SignUp';
import Cookies from './pages/public/Cookies';
import LandingPage from './pages/public/LandingPage';
import NotFound from './pages/public/NotFound';
import PrivacyPolicy from './pages/public/PrivacyPolicy';
import DataImport from './pages/private/DataImport';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/details/:application_id"
          element={
            <ProtectedRoute>
              <JobApplicationDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-job-application"
          element={
            <ProtectedRoute>
              <JobApplicationEdit key="create-job-application" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit/:application_id"
          element={
            <ProtectedRoute>
              <JobApplicationEdit key="edit-job-application" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chrome-extension"
          element={
            <ProtectedRoute>
              <ChromeExtension key="chrome-extension" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/data-import"
          element={
            <ProtectedRoute>
              <DataImport key="data-import" />
            </ProtectedRoute>
          }
        />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/about" element={<LandingPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
