import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => (
  <React.Fragment>
    <Helmet>
      <title>Not Found - Jobsy</title>
    </Helmet>
    <section className="hero">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-1 has-text-danger">404</h1>
          <p className="subtitle is-4">Oops! The page you are looking for doesn't exist.</p>
          <div className="buttons is-centered mt-5">
            <Link to="/" className="button is-info is-medium">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default NotFound;
